import React, { useEffect, useRef } from 'react'
import { Row, Col } from 'react-flexbox-grid'
import { default as Layout } from 'layouts/Main'
import SEO from 'components/SEO'
import Sector from 'components/Sector'
import Banner from 'components/Banner'
import ButtonScroll from 'components/ButtonScroll'
import Calc from 'components/Calc'
import { coefficientCosDecreasing, coefficientCosIncreasing } from 'utils/calc'
import FeedbackButton from 'components/FeedbackButton'
import Contacts from 'components/Contacts'
import route from 'utils/route'
import FAQ from 'components/FAQ'
import useSiteMetadata from 'hooks/useSiteMetadata'
import Catalog from './components/Catalog'

const Databases = props => {
	const catalogRef = useRef(null)
	const { siteUrl } = useSiteMetadata()

	const { pageContext, location } = props
	let { number, slug } = pageContext

	let title = 'Базы компаний с email и телефонами в Excel — «Парсик»'
	if (number > 1) title = `Базы компаний с email и телефонами в Excel. Часть ${number}`
	const description = 'Базы компаний, организаций, фирм, предприятий в Excel по всей России. Скачать актуальную базу'
	const keywords = 'база, скачать, excel, ексель, телефоны, email, компании, поставщики, фирмы, организации'
	const price = 1290

	// Прокрутка страницы до элемента из хеша
	useEffect(() => {
		const hash = window.location.hash
		if (hash) {
			const element = document.getElementById(hash.substring(1))

			// Если элемент найден и человек не прокручивал страницу
			if (element && window.scrollY === 0) window.scrollTo({ top: element.getBoundingClientRect().top })
		}
	}, [])

	return (
		<Layout>
			<SEO title={title} description={description} keywords={keywords} canonical={route.custom({ slug })} />

			<Banner
				title="Базы компаний"
				description="Для рассылок email, whatsapp, telegram и др."
				image={{ name: 'home', width: 506, height: 392 }}
			>
				<ButtonScroll theme="light" size="lg" to={catalogRef} on="top">
					Выбрать базу
				</ButtonScroll>
			</Banner>

			<div id="catalog" ref={catalogRef}>
				<Sector title="Скачайте готовую базу" titleCentered>
					<Row>
						<Col lgOffset={1} lg={10}>
							<div className="text-lg mb-lg text-center">
								Мы собрали наиболее популярные базы компаний.
								<br />
								Воспользуйтесь поиском, чтобы найти интересующую вас базу
							</div>
						</Col>
					</Row>
					<Catalog {...props} />
				</Sector>
			</div>

			<Sector color="blue" title="Закажите базу компаний" isGradient>
				<div className="fs-h4">
					<div className="mb-lg">
						<p>
							Отправьте заявку и&nbsp;получите базу компаний по&nbsp;вашим параметрам. Напишите подробно о&nbsp;том,
							какие компании нужно собрать. Мы&nbsp;ответим вам в&nbsp;течение рабочего дня
						</p>
					</div>
					<p>
						<FeedbackButton theme="light" size="lg">
							Заказать парсинг
						</FeedbackButton>
					</p>
				</div>
			</Sector>

			<Calc
				isFormToggle
				rounding={10}
				initialValues={{
					required: { db: `builder` }, // TODO: удалить посте оптимизации api
					type: 'base',
					sources: true,
					sourcesCount: 1,
					elements: true,
					elementsCount: 50000,
					speed: true,
					speedCount: 5,
					hoursCount: 1,
					source: siteUrl + location.pathname,
				}}
				button={{
					base: 'Заказать базу',
					order: 'Заказать парсинг',
				}}
				help={{
					base: (
						<>
							<p>Это предварительная стоимость</p>
							<p>
								Итоговая цена может быть больше или меньше после индивидуальной оценки специалистом
								и&nbsp;согласовывается отдельно
							</p>
						</>
					),
					order: (
						<>
							<p>Это предварительная стоимость</p>
							<p>
								Итоговая цена может быть больше или меньше после индивидуальной оценки специалистом
								и&nbsp;согласовывается отдельно
							</p>
						</>
					),
				}}
				fields={[
					{
						field: {
							type: 'radio',
							name: 'type',
							value: 'base',
							children: 'Заказать другую базу',
							helper: 'Вы&nbsp;получите персональную базу компаний по&nbsp;вашим параметрам',
							size: 'lg',
						},
						price,
						showPrice: price,
						showPricePrefix: 'от ',
					},
					{
						field: {
							type: 'radio',
							name: 'type',
							value: 'order',
							children: 'Заказать парсинг',
							helper:
								'Мы&nbsp;сможем скачать информацию с&nbsp;любых сайтов и&nbsp;предоставим вам в&nbsp;виде удобного Excel файла',
							size: 'lg',
						},
						fields: [
							{
								field: {
									type: 'checkbox',
									name: 'sources',
									children: 'Количество источников',
									disabled: true,
									helper:
										'Источник&nbsp;&mdash; это интернет-ресурс, с&nbsp;которого будут скачиваться данные. Информацию можно парсить из&nbsp;нескольких источников сразу и&nbsp;собирать данные в&nbsp;один Excel файл',
								},
								count: 'sourcesCount',
								showPrice: 1500,
								fields: [
									{
										field: {
											type: 'slider',
											name: 'sourcesCount',
											min: 1,
											max: 10,
										},
										price: 1500,
									},
								],
							},
							{
								field: {
									type: 'checkbox',
									name: 'elements',
									children: 'Общее количество позиций',
									disabled: true,
									helper:
										'Позиция&nbsp;&mdash; это отдельный товар интернет-магазина, объявление, изображение или вся страница. Приблизительное количество позиций для парсинга',
								},
								count: 'elementsCount',
								fields: [
									{
										field: {
											type: 'slider',
											name: 'elementsCount',
											min: 50000,
											max: 1000000,
											step: 50000,
										},
										priceCalc: v => 0.04 * coefficientCosDecreasing(v, 50000, 1000000),
									},
								],
							},
							{
								field: {
									type: 'checkbox',
									name: 'speed',
									children: 'Частота обновления в месяц',
									disabled: true,
									helper: 'Ежемесячная частота обновления данных в&nbsp;файле парсинга',
								},
								count: 'speedCount',
								fields: [
									{
										field: {
											type: 'slider',
											name: 'speedCount',
											min: 5,
											max: 30,
										},
										priceCalc: v => 200 * coefficientCosIncreasing(v, 5, 30),
									},
								],
							},
							{
								field: {
									type: 'checkbox',
									name: 'hours',
									children: 'Обработка данных',
									helper:
										'Примерное время, необходимое для дополнительной обработки данных парсинга. Например, изменение цены товаров или повышение уникальности текста',
								},
								count: 'hoursCount',
								fields: [
									{
										field: {
											type: 'slider',
											name: 'hoursCount',
											min: 1,
											max: 5,
										},
										price: 800,
									},
								],
								showPrice: 800,
								showPricePostfix: ' / час',
							},
						],
					},
				]}
				inner={{
					base: {
						type: 'contactForm',
						props: {
							fields: {
								name: {
									required: true,
								},
								email: {
									required: true,
								},
								message: {},
							},
						},
					},
					order: {
						type: 'contactForm',
						props: {
							fields: {
								name: {
									required: true,
								},
								email: {
									required: true,
								},
								message: {},
							},
						},
					},
				}}
			/>

			<Sector color="gray" title="Вопросы и ответы" titleCentered>
				<Row className="text-center mb-lg text-lg">
					<Col lgOffset={1} lg={10}>
						<p>Частые вопросы по получению баз компаний</p>
					</Col>
				</Row>
				<div className="mb-lg">
					<FAQ
						currentIndexes={[0]}
						isNumbers={true}
						items={[
							{
								title: 'Что такое база компаний?',
								description: (
									<div className="p-no-last">
										Это Excel файл, который содержит контакты всех организаций, фирм, предприятий по&nbsp;всей
										территории России или в&nbsp;регионах. Каждая запись компании в&nbsp;базе обязательно содержит один
										или несколько контактов. Например: городской или мобильный номер телефона, город, область,
										email-адрес. Записи базы отсортированы в&nbsp;порядке убывания смыслового значения ключевых слов,
										текстов названий и&nbsp;описаний сайта.
									</div>
								),
							},
							{
								title: 'Как создается база компаний?',
								description: (
									<div className="p-no-last">
										<p>
											Наша программа парсер работает круглосуточно, сканирует и&nbsp;анализирует все сайты в&nbsp;России
											(в&nbsp;зонах .ru, .рф, .su) и&nbsp;собирает информацию в&nbsp;единую базу данных. После этого для
											формирования базы отбираются те&nbsp;записи компаний, которые лучше всего подходят по&nbsp;смыслу.
											Для поиска организаций используются точные соответствия ключевых слов и&nbsp;словосочетаний.
											Мы&nbsp;так&nbsp;же используем специальные &laquo;стоп-слова&raquo;, которые необходимы для отсева
											ошибочного мусора и&nbsp;неподходящих компаний.
										</p>
										<p>
											Дополнительно мы&nbsp;используем морфологическое преобразование слов с&nbsp;учетом всех возможных
											вариантов, например: &laquo;компания&raquo;, &laquo;наших компаний&raquo;, &laquo;в&nbsp;нашей
											компании&raquo;.
										</p>
										<p>В&nbsp;итоге получается наиболее точная и&nbsp;объемная база.</p>
									</div>
								),
							},
							{
								title: 'Как скачать базу компаний?',
								description: (
									<div className="p-no-last">
										Сперва найдите интересующую вас базу на&nbsp;этой странице. Вы&nbsp;можете воспользоваться поиском
										по&nbsp;каталогу баз компаний или ознакомиться со&nbsp;всем каталогом с&nbsp;помощью навигации.
										Затем, перейдите на&nbsp;страницу с&nbsp;базой и&nbsp;нажмите кнопку &laquo;Скачать&raquo;.
										Мы&nbsp;вышлем на&nbsp;вашу почту письмо со&nbsp;ссылкой для скачивания базы.
									</div>
								),
							},
							{
								title: 'Как использовать базу компаний?',
								description: (
									<div className="p-no-last">
										<p>С&nbsp;помощью базы компаний вы&nbsp;можете:</p>
										<ol>
											<li>Делать рассылку на&nbsp;почту вашего коммерческого предложения потенциальным клиентам</li>
											<li>Предлагать ваши услуги с&nbsp;помощью автоматических холодных звонков</li>
											<li>
												Сосредоточиться на&nbsp;бизнесе, имея под рукой контактные данные всех компаний в&nbsp;одном
												месте
											</li>
											<li>Принимать решения на&nbsp;основе анализа данных на&nbsp;любых стадиях вашего бизнеса</li>
										</ol>
									</div>
								),
							},
							{
								title: 'Как заказать базу по&nbsp;моим параметрам?',
								description: (
									<div className="p-no-last">
										Если вы&nbsp;не&nbsp;нашли нужную вам базу в&nbsp;каталоге или база не&nbsp;удовлетворяет вашим
										потребностям, то&nbsp;вы&nbsp;можете заказать свою базу по&nbsp;вашим пожеланиям. Для заказа просто
										отправьте заявку с&nbsp;сайта. В&nbsp;сообщении напишите, какие компании вам нужны. Мы&nbsp;сообщим
										вам стоимость и&nbsp;сроки выполнения работы.
									</div>
								),
							},
						]}
					/>
				</div>
				<blockquote className="blockquote mb-lg">
					Нажмите кнопку &laquo;Задать вопрос&raquo; чтобы получить ответ на&nbsp;интересующий вас вопрос.
					Мы&nbsp;ответим вам в&nbsp;ближайшее время
				</blockquote>
				<div className="text-center">
					<FeedbackButton theme="primary" size="lg">
						Задать вопрос
					</FeedbackButton>
				</div>
			</Sector>

			<Contacts />
		</Layout>
	)
}

export default Databases
